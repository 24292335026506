<template>
  <div class="tableData" style="font-size: 10px;">
    <div style="margin-top: 10px;font-size: 14px;">
      <span style="margin-left: 10px;"></span>我们很遗憾您作出注销悄然声色账户的决定，为了保障您的权益，请在注销前详细阅读本须知的所有内容。当您勾选“我已阅读并同意《账户注销须知》”并点击“申请注销”或类似按钮时，您的行为表示您同意本须知的约定。
    </div>
    <div style="margin-top: 10px;font-size: 14px;">
      <span style="margin-left: 10px;"></span>1、您所申请注销的悄然声色账户应当是您依照《悄然声色服务条款》的约定注册并由您本人使用的账户。您应确保您有权决定该账户的注销事宜，不侵犯任何第三方的合法权益，如因此引发任何投诉争议，由您自行承担并使悄然声色免责。
    </div>
    <div style="margin-top: 10px;font-size: 14px;">
      <span style="margin-left: 10px;"></span>2、注销悄然声色账户是不可逆操作，操作之前，请确认与悄然声色相关的所有服务均已妥善处理。
    </div>
    <div style="margin-top: 10px;font-size: 14px;">
      <span style="margin-left: 10px;"></span>3、注销悄然声色账户，除法律法规另有规定外，将视为您自行放弃该账户及相关权益，您将无法再登录、使用此账户或找回此账户内的任何信息及权益，包括但不限于个人身份信息、账户信息等。
    </div>
    <div style="margin-top: 10px;font-size: 14px;">
      <span style="margin-left: 10px;"></span>4、在您申请注销悄然声色账户前，您应当确保该账户处于正常状态，且在注销申请提起时该账户：
      <div style="margin-top: 5px;font-size: 14px;">（1）没有未完成的订单或售后流程；</div>
      <div style="margin-top: 5px;font-size: 14px;">（2）该账户不存在未了结的合同关系，或悄然声色认为注销该账户会由此产生未了结的权利义务而产生纠纷的情况。</div>

      如您申请注销账号时有如上情形，可能无法自助完成注销流程，您可以以本须知第9条公示方式联系我们由人工客服予以处理。
    </div>
    <div style="margin-top: 10px;font-size: 14px;">
      <span style="margin-left: 10px;"></span>5、您承诺您的悄然声色账户注销申请一经提交，您不会以任何理由要求悄然声色予以撤销。
    </div>
    <div style="margin-top: 10px;font-size: 14px;">
      <span style="margin-left: 10px;"></span>6、注销悄然声色账户后，我们会将依据相关法律法规应当保留的个人信息从系统中去除，使其保持不可被检索、访问状态，或对其进行匿名化处理。您知晓并理解，根据相关法律规定，相关商品和服务信息、交易记录须在悄然声色后台保存一定的时间，但请您知悉，我们将严格按照相关法律的要求保存您的信息。
    </div>
    <div style="margin-top: 10px;font-size: 14px;">
      <span style="margin-left: 10px;"></span>7、如您使用微信、支付宝等第三方账号绑定生成悄然声色账户并在此基础上开通使用第三方服务的，在您注销该悄然声色账户后，前述第三方服务也将无法继续使用，且其中的所有信息及记录将无法找回。您理解并同意，悄然声色无法协助您重新恢复前述服务。
    </div>
    <div style="margin-top: 10px;font-size: 14px;">
      <span style="margin-left: 10px;"></span>8、在悄然声色账号注销期间，如果您的悄然声色账户被他人投诉、被国家机关调查或正处于诉讼、仲裁程序中，悄然声色有权自行终止该账户的注销流程而无需另行得到您的同意。
      请注意，注销您的悄然声色账户并不代表此账户注销前的账户行为和相关责任得到豁免或减轻。
    </div>
    <div style="margin-top: 10px;font-size: 14px;">
      <span style="margin-left: 10px;"></span>9、如您对注销账号有任何意见和询问，可联系QQ客服：800061106；邮箱：dzdk123456@qq.com
    </div>
  </div>
</template>

<script>
export default {
  components: { },
  props: {},
  data() {
    return {
      isLoading: true
    };
  },
  watch: {},
  computed: {},
  methods: {
  },
  created() {
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  background: no-repeat 0 0;
  background-size: 100% auto;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
body {
  height: auto;
  -webkit-user-select: none;
  user-select: none;
  color: #333;
  background-color: #ffffff;
}
li {
  list-style: none;
  padding: 0.5em 0;
  width: 100%;
}
.tableData {
  width: 100%;
  overflow: auto;
}
table {
  border-collapse: collapse;
  width: 600px;
}
table,
table tr th,
table tr td {
  white-space: nowrap;
}
th,
td {
  padding: 0.5em;
}
#app {
  font-family: -apple-system-font, BlinkMacSystemFont, "Helvetica Neue",
  "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei UI", "Microsoft YaHei",
  Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0.8em;
}
.right {
  text-align: right;
}
.middle {
  text-align: center;
}
p {
  line-height: 1.6em;
  word-break: break-all;
  padding: 0.4em 0;
}
h3,
h4 {
  padding: 0.6em 0;
  margin-bottom: 0.5em;
}
.layout {
  padding: 1em 4%;
  letter-spacing: 1px;
}
.title {
  font-size: 1.5em;
  font-weight: 700;
  margin-bottom: 2em;
  text-align: center;
}
.sub_list > * {
  padding: 0.6em 0;
}
</style>